import React, { useRef } from "react"
import Hero from "../components/hero/Hero"
import Block from "../components/block/Block"
import "../scss/styles.scss"
import Layout from "../layouts/layout/Layout"
import PostsBlock from "../components/postsBlock/PostsBlock"
import Seo from "../components/seo/Seo"
import { graphql, useStaticQuery } from "gatsby"
import gsap from "gsap";
import { CSSPlugin } from "gsap/CSSPlugin";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";


gsap.registerPlugin(CSSPlugin, CSSRulePlugin, ScrollTrigger);

export default function Home() {
  const wrapper = useRef(null);
  const { site } = useStaticQuery(query)
  const {
    description,
    image,
  } = site.siteMetadata
  
  return (
    <div className="md">
      <Seo
        title={"Home"}
        description={description || "nothin’"}
        image={image}
        article
      />
     
      <div className="md-wrapper" ref={wrapper}>
        <Layout>
          <Hero />
          <Block
            title="popular recipes"
            linkPath="/recipes"
            linkText="see all recipes"
            type="recipes"
          >
            <PostsBlock type="recipes" />
          </Block>
        </Layout>
      </div>
    </div>
  )
}

const query = graphql`
  query homeSeo {
    site {
      siteMetadata {
        title
        titleTemplate
        author
        description
        url
        image
        twitterUsername
      }
    }
  }
`
