import React from "react"
import { Link } from "gatsby"

function Block({ children, title, linkPath, linkText , type}) {
  return (
    <div className={`md-block md-block--${type}`}>
      <div className="md-block__container">
        <h2 className="md-title">{title}</h2>
        {children}
        <Link className="md-link" to={linkPath}>
          {linkText}
        </Link>
      </div>
    </div>
  )
}

export default Block
