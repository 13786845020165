import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Posts from "../posts/Posts";


function PostsBlock({ type }) {

  const data = useStaticQuery(
    graphql`
      query {
        allContentfulRecipes(filter: { node_locale: { eq: "en-US" } }, sort: {order: DESC, fields: publishDate}) {
          edges {
            node {
              title
              slug
              contentful_id
              cooktime
              createdAt
              preptime
              publishDate(formatString: "MMMM Do, YYYY")
              servings
              spaceId
              updatedAt
              tags
              image {
                fluid {
                  tracedSVG
                  srcWebp
                  srcSetWebp
                  src
                  sizes
                  base64
                  aspectRatio
                  srcSet
                }
              }
            }
          }
        }
        allContentfulBlog(filter: { node_locale: { eq: "en-US" } },sort: {order: DESC, fields: publishDate}) {
          edges {
            node {
              title
              slug
              image {
                fluid {
                  tracedSVG
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                }
              }
              description {
                json
              }
              createdAt
              publishDate(formatString: "MMMM Do, YYYY")
              spaceId
              tags
              updatedAt
              contentful_id
            }
          }
        }
      }
    `
  );
  

  const posts = type === "recipes" ? data.allContentfulRecipes.edges : data.allContentfulBlog.edges ;
  return (
    <Posts type={type} posts={posts} postsNumber={3}/>
  )
}

export default PostsBlock
