import React from "react"
import { Link } from "gatsby"

function Hero() {

  return (
    <div className="md-hero" name="mdhero">
      <div className="md-hero__bg md-hero__bg--left" ></div>
      <div className="md-hero__bg md-hero__bg--right">
      </div>
      <div className="md-hero__content">
        <div className="md-hero__col">
          <h1 className="md-hero__title">
            <span className="md-hero__line-text">We're not chefs</span>
            <span className="md-hero__line-text">but we can teach you</span>
            <span className="md-hero__line-text">our simple ways.</span>
          </h1>
          <div className="md-hero__btn-wrapper">
            <Link className="md-link" to="/recipes">
              see all recipes
            </Link>
            <Link className="md-link md-link--transparent" to="/about">
              learn about us
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
